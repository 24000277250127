.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.color-nav{
  background-color: #071330;
  align-items: center;
}

.accordion-button {
  color: #C3CEDA !important;
  background-color: #0C4160  !important;
  box-shadow: none !important;
  outline: none !important;
}


.accordion-button:focus {
box-shadow: none !important;
border-color: rgba(0,0,0,.125);
outline: none !important;
}

.accordion-button:not(.collapsed) {
color: #212529;
box-shadow: none;
border-color: rgba(0,0,0,.125);
outline: none !important;
}

.accordion{
  box-shadow: none !important;
  color: #071330 !important;
  background-color: #071330 !important;
  outline: none !important;
  border: none !important;
  text-align: left;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23C3CEDA'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-item, .accordion-item:focus, .accordion-item:active,
.accordion-header, .accordion-header:focus, .accordion-header:active,
.accordion-button, .accordion-button:focus, .accordion-button:active {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.card{
  background-color: #071330 !important;
}

.listgroup{
  /* background-color: #2f56b1 !important; */
  background-color: #5f83a3 !important;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}